<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col cols="12">
          <div class="form-group">
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </div>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowNewRequest"
          >
            New Reservation
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(departure)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.departure_date} ${data.item.departure_time}`) }}
          </div>
        </template>

        <template #cell(arrival)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.arrival_date} ${data.item.arrival_time}`) }}
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm mr-1"
              @click="onShowViewForm(row.item)"
            >
              View
            </b-button>

            <b-button
              size="sm"
              variant="danger"
              @click="onShowCancelForm(row.item)"
            >
              Cancel
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- modal new reservation -->
    <b-modal
      id="modal-reservation-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          novalidate
          role="form"
        >
          <b-tabs>
            <b-tab
              title="Reservation Details"
              active
            >
              <ValidationProvider
                #default="{ errors }"
                name="company"
                vid="company"
                rules="required"
              >
                <b-form-group>
                  <label for="company">Company</label>
                  <b-form-select
                    id="company"
                    v-model="reservation.company"
                    :options="list.companies"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || state.file.viewing"
                    @change="onCompanyChange"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- Please Select Company --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                #default="{ errors }"
                name="organization"
                vid="organization"
                rules="required"
              >
                <b-form-group>
                  <label for="organization">Organization</label>
                  <b-form-select
                    id="organization"
                    v-model="reservation.organization"
                    :options="filteredOrganizations"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || state.file.viewing"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- Please Select Organization --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                #default="{ errors }"
                name="reservation type"
                vid="reservation_type"
                rules="required"
              >
                <b-form-group>
                  <label for="reservation_type">Reservation Type</label>
                  <b-form-select
                    id="reservation_type"
                    v-model="reservation.reservation_type"
                    :options="list.reservationTypes"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || state.file.viewing"
                    @change="onReservationTypeChange"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- Please Select Reservation Type --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                #default="{ errors }"
                name="departure date"
                vid="departure_date"
                rules="required"
              >
                <b-form-group>
                  <label for="departure_date">Departure Date</label>
                  <b-input
                    id="departure_date"
                    v-model="reservation.departure_date"
                    type="date"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || state.file.viewing"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                #default="{ errors }"
                name="departure time"
                vid="departure_time"
                rules="required"
              >
                <b-form-group>
                  <label for="departure_time">Departure Time</label>
                  <b-input
                    id="departure_time"
                    v-model="reservation.departure_time"
                    type="time"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || state.file.viewing"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                #default="{ errors }"
                name="arrival date"
                vid="arrival_date"
                rules="required"
              >
                <b-form-group>
                  <label for="arrival_date">Arrival Date</label>
                  <b-input
                    id="arrival_date"
                    v-model="reservation.arrival_date"
                    type="date"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || state.file.viewing"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                #default="{ errors }"
                name="arrival time"
                vid="arrival_time"
                rules="required"
              >
                <b-form-group>
                  <label for="arrival_time">Arrival Time</label>
                  <b-input
                    id="arrival_time"
                    v-model="reservation.arrival_time"
                    type="time"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || state.file.viewing"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                #default="{ errors }"
                name="vehicle type"
                vid="vehicle_type"
                rules="required"
              >
                <b-form-group>
                  <label for="vehicle_type">Vehicle Type</label>
                  <b-form-select
                    id="vehicle_type"
                    v-model="reservation.vehicle_type"
                    :options="list.vehicleTypes"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || state.file.viewing"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- Please Select Vehicle Type --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                v-if="reservation.reservation_type !== 'Service Driver'"
                #default="{ errors }"
                name="passenger count"
                vid="passenger_count"
                rules="required|numeric|max_value:500"
              >
                <b-form-group>
                  <label for="passenger_count">Passenger Count</label>
                  <b-input
                    id="passenger_count"
                    v-model="reservation.passenger_count"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || state.file.viewing"
                    max="500"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                #default="{ errors }"
                name="purpose"
                vid="purpose"
                rules="required"
              >
                <b-form-group>
                  <label for="purpose">Purpose of Request (Please State Itinerary)</label>
                  <b-textarea
                    id="purpose"
                    v-model="reservation.purpose"
                    rows="6"
                    max-rows="6"
                    :state="errors.length > 0 ? false : null"
                    autocomplete="off"
                    :disabled="state.busy || state.file.viewing"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-tab>

            <b-tab title="Attachments">
              <b-row v-if="!state.file.viewing">
                <b-col cols="12">
                  <b-form-file
                    v-model="list.files"
                    accept=".jpg, .jpeg, .png"
                    multiple
                    class="mb-1"
                    :disabled="state.busy || (parseInt(state.allowSubmit) === 1 && !state.file.having)"
                  />
                </b-col>

                <b-col cols="12">
                  <b-button
                    variant="success"
                    :disabled="state.busy || state.file.uploading || list.files.length < 1 || (parseInt(state.allowSubmit) === 1 && !state.file.having)"
                    class="mr-1"
                    size="sm"
                    @click="onUploadAttachment"
                  >
                    {{ state.file.uploading ? 'Uploading Files...' : 'Upload Files' }}
                  </b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    :disabled="reservation.file_list.length < 1"
                    @click="onRemoveAllFile"
                  >
                    {{ state.file.removing ? 'Removing Files...' : 'Remove Files' }}
                  </b-button>
                </b-col>
              </b-row>

              <b-table
                v-if="reservation.file_list.length > 0 || state.file.viewing"
                class="mt-1"
                :fields="attachmentTableSettings.fields"
                :items="reservation.file_list"
                responsive
                striped
                hover
              >
                <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-button
                      size="sm"
                      class="mr-1"
                      :disabled="state.file.previewing"
                      @click="onPreviewAttachment(row.item)"
                    >
                      Preview
                    </b-button>
                  </div>
                </template>
              </b-table>
              <div
                v-if="!state.file.having && !state.file.viewing"
                class="form-group mt-5"
              >
                <b-form-checkbox
                  id="submit_proceed"
                  v-model="state.allowSubmit"
                  name="submit_proceed"
                  value="1"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Allow me to proceed without attachment.
                </b-form-checkbox>
              </div>
            </b-tab>
          </b-tabs>

        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy || parseInt(state.allowSubmit) === 0"
          @click="ok()"
        >
          Submit
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          {{ state.file.viewing ? 'Close Window' : 'Cancel' }}
        </b-button>
      </template>
    </b-modal>

    <!-- Modal View Form -->
    <b-modal
      id="modal-view-form"
      scrollable
      no-close-on-backdrop
      title="View Detail"
    >
      <b-card no-body>
        <form
          novalidate
          role="form"
        >
          <b-tabs>
            <b-tab
              title="Reservation Details"
              active
            >
              <b-form-group>
                <label for="reserved_at">Reserved At</label>
                <b-input
                  id="reserved_at"
                  v-model="reservation.created_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="status">Status</label>
                <b-input
                  id="status"
                  v-model="reservation.status"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="company">Company</label>
                <b-input
                  id="company"
                  v-model="reservation.company_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="organization">Organization</label>
                <b-input
                  id="organization"
                  v-model="reservation.organization_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="reservation_type">Reservation Type</label>
                <b-input
                  id="reservation_type"
                  v-model="reservation.reservation_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="vehicle_type">Vehicle Type</label>
                <b-input
                  id="vehicle_type"
                  v-model="reservation.vehicle_type_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="reservation.reservation_type === 'Service Vehicle'">
                <label for="passenger_count">Passenger Count</label>
                <b-input
                  id="passenger_count"
                  v-model="reservation.passenger_count"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="departure_date">Departure Date</label>
                <b-input
                  id="departure_date"
                  v-model="reservation.departure_date"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="departure_time">Departure Time</label>
                <b-input
                  id="departure_time"
                  v-model="reservation.departure_time"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="arrival_date">Arrival Date</label>
                <b-input
                  id="arrival_date"
                  v-model="reservation.arrival_date"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="arrival_time">Arrival Time</label>
                <b-input
                  id="arrival_time"
                  v-model="reservation.arrival_time"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="purpose">Purpose</label>
                <b-textarea
                  id="purpose"
                  v-model="reservation.purpose"
                  type="text"
                  rows="6"
                  max-rows="6"
                  :disabled="true"
                />
              </b-form-group>
            </b-tab>

            <b-tab
              v-if="reservation.attachments.length > 0"
              title="Attachments"
            >
              <b-table
                class="mt-1"
                :fields="attachmentTableSettings.fields"
                :items="reservation.attachments"
                responsive
                striped
                hover
              >
                <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-button
                      size="sm"
                      class="mr-1"
                      @click="onPreviewAttachment(row.item)"
                    >
                      Preview
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-tab>
          </b-tabs>
        </form>
      </b-card>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

    <!-- modal cancel form -->
    <b-modal
      id="modal-cancel-form"
      scrollable
      no-close-on-backdrop
      title="Cancel Request"
      @ok="onCancelTransaction"
    >
      <ValidationObserver ref="form2">
        <form
          novalidate
          role="form2"
        >
          <ValidationProvider
            #default="{ errors }"
            name="cancellation reason"
            vid="cancellation_reason"
            rules="required|max:200"
          >
            <div class="form-group">
              <label for="cancellation_reason">Cancellation Reason</label>
              <b-form-textarea
                id="cancellation_reason"
                v-model="cancellation.cancellation_reason"
                rows="6"
                max-rows="6"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter cancellation reason"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          Proceed
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${file.name}]`"
      size="lg"
    >
      <div class="d-flex justify-content-center">
        <b-img
          fluid
          :src="file.preview"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, UserReservationService, UserActionService, UserAttachmentService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'
import _ from 'lodash'

export default {
  name: 'UserMyReservations',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'My Reservations'
    }
  },

  mixins: [formatter, url],

  data () {
    return {
      state: {
        busy: false,
        viewing: false,
        file: {
          having: false,
          removing: false,
          uploading: false,
          previewing: false
        },
        allowSubmit: 0
      },
      file: {
        name: '',
        preview: null
      },
      list: {
        files: [],
        companies: [],
        organizations: [],
        vehicleTypes: [],
        reservationTypes: [
          { text: 'Service Driver', value: 'Service Driver' },
          { text: 'Service Vehicle', value: 'Service Vehicle' },
          { text: 'Service Driver And Vehicle', value: 'Service Driver And Vehicle' }
        ]
      },
      reservation: {
        id: 0,
        company: null,
        organization: null,
        reservation_type: 'Service Driver',
        departure_date: this.dateFormatter(Date.now()),
        departure_time: '',
        arrival_date: this.dateFormatter(Date.now()),
        arrival_time: '',
        vehicle_type: null,
        passenger_count: 0,
        purpose: '',
        file_list: [],
        file_path: null,
        attachments: []
      },
      cancellation: {
        id: 0,
        cancellation_reason: ''
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'reserve at' },
          { key: 'reservation_type' },
          { key: 'vehicle_type_name', label: 'Vehicle Type' },
          'departure',
          'arrival',
          { key: 'status' }
        ]
      },
      attachmentTableSettings: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.viewing ? 'View Reservation' : 'New Reservation'
    },

    filteredOrganizations () {
      return _.filter(
        this.list.organizations, { company_id: this.reservation.company }
      ).map(organization => ({
        text: organization.name,
        value: organization.id
      }))
    }
  },

  watch: {
    'reservation.file_list' (value) {
      if (value.length > 0) {
        this.state.file.having = true
      } else {
        this.state.file.having = false
      }
    },

    'state.file.having' (value) {
      if (value) {
        this.state.allowSubmit = 1
      } else {
        this.state.allowSubmit = 0
      }
    }
  },

  mounted () {
    core.index()
    this.fetchList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await UserReservationService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_status=${ctx.filter_status}&filter_text=${ctx.filter}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async fetchList () {
      await SharedListService.getCompanies().then(({ data }) => {
        this.list.companies = data.map(company => ({
          text: company.name,
          value: company.id
        }))
      })

      await SharedListService.getOrganizations().then(({ data }) => {
        this.list.organizations = data
      })

      await SharedListService.getVehicleTypes().then(({ data }) => {
        this.list.vehicleTypes = data.map(vehicleType => ({
          text: vehicleType.name,
          value: vehicleType.id
        }))
      })
    },

    onShowNewRequest () {
      if (!this.state.viewing) {
        this.reservation.id = 0
        this.reservation.company = null
        this.reservation.organization = null
        this.reservation.reservation_type = 'Service Driver'
        this.reservation.departure_date = this.dateFormatter(Date.now())
        this.reservation.departure_time = ''
        this.reservation.arrival_date = this.dateFormatter(Date.now())
        this.reservation.arrival_time = ''
        this.reservation.vehicle_type = null
        this.reservation.passenger_count = 0
        this.reservation.purpose = ''
        this.reservation.file_path = ''
        this.reservation.file_list = []
        this.list.files = []
        this.state.file = {
          having: false,
          removing: false,
          uploading: false,
          previewing: false
        }
        this.state.allowSubmit = 0
        this.$bvModal.show('modal-reservation-form')
      }
    },

    onShowViewForm (item) {
      this.state.viewing = true
      this.reservation.id = item.id
      this.reservation.created_at = this.dateTimeFormatter(item.created_at)
      this.reservation.status = item.status
      this.reservation.user_name = item.user_name
      this.reservation.company_name = item.company_name
      this.reservation.organization_name = item.organization_name
      this.reservation.reservation_type = item.reservation_type
      this.reservation.departure_date = item.departure_date
      this.reservation.departure_time = this.timeFormatterTwelveHours(`${item.departure_date} ${item.departure_time}`)
      this.reservation.arrival_date = item.arrival_date
      this.reservation.arrival_time = this.timeFormatterTwelveHours(`${item.arrival_date} ${item.arrival_time}`)
      this.reservation.vehicle_type_name = item.vehicle_type_name
      this.reservation.passenger_count = item.passenger_count
      this.reservation.purpose = item.purpose
      this.reservation.attachments = item.attachments
      this.reservation.file_path = item.file_path
      this.$bvModal.show('modal-view-form')
    },

    onShowCancelForm (item) {
      this.cancellation.id = item.id
      this.$bvModal.show('modal-cancel-form')
    },

    onCompanyChange () {
      this.reservation.organization = null
    },

    onReservationTypeChange (reservationType) {
      if (reservationType === 'Service Driver') {
        this.reservation.vehicle_type = null
        this.reservation.passenger_count = 0
      }
    },

    async onCancelTransaction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form2.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Do you really want to cancel reservation?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormCancelTransaction()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormCancelTransaction () {
      return new Promise(resolve => {
        this.state.busy = true
        UserActionService.cancelReservation(this.cancellation).then(({ data }) => {
          this.$bvModal.hide('modal-cancel-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Do you really want to create reservation?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserReservationService.post(this.reservation).then(({ data }) => {
          this.$bvModal.hide('modal-reservation-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onUploadAttachment () {
      this.state.file.uploading = true

      const formBody = new FormData()

      let counter = 0

      this.list.files.forEach(file => {
        formBody.append(`file[${counter}]`, file)
        counter++
      })

      formBody.append('file_path', this.reservation.file_path)

      await UserAttachmentService.upload(formBody).then(response => {
        this.reservation.file_path = response.data.file_path
        this.reservation.file_list.push(...response.data.file_list)
        this.list.files = []
        this.state.file.uploading = false
      }).catch(() => {
        this.list.files = []
        this.state.file.uploading = false
      })
    },

    async onRemoveFile (file) {
      this.state.file.removing = true

      await UserAttachmentService.removeFile(this.reservation.file_path, file.file_hash_name).then(() => {
        this.reservation.file_list = _.reject(this.reservation.file_list, currentObject => {
          return currentObject.file_hash_name === file.file_hash_name
        })
        this.state.file.removing = false
      }).catch(() => {
        this.state.file.removing = false
      })
    },

    async onRemoveAllFile () {
      this.state.file.removing = true

      await UserAttachmentService.removeAllFile(this.reservation.file_path).then(() => {
        this.reservation.file_path = ''
        this.reservation.file_list = []
        this.state.file.removing = false
      }).catch(() => {
        this.list.files = []
        this.state.file.removing = false
      })
    },

    async onPreviewAttachment (item) {
      if (this.state.viewing) {
        this.file.name = item.file_original_name
        this.file.preview = this.getImageUrl(`${this.reservation.file_path}/${item.file_hash_name}`)
        this.$bvModal.show('modal-preview-image')
      } else {
        this.file.name = item.file_original_name
        this.file.preview = this.getImageUrl(`uploads/${this.reservation.file_path}/${item.file_hash_name}`)
        this.$bvModal.show('modal-preview-image')
      }
    }
  }
}
</script>
